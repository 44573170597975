<template>
  <div class="inpatient_container">
    <div class="order_main">
      <el-table :data="recipeData" border="" height="100%" style="width: 100%" v-loading="loading" @row-click="on_select">
        <el-table-column label="科室" prop="deptName"></el-table-column>
        <el-table-column label="医生" prop="doctorName"></el-table-column>
        <el-table-column label="就诊人" prop="patName"></el-table-column>
        <el-table-column label="就诊时间" prop="diagnTime"></el-table-column>
      </el-table>
    </div>
    <el-button type="primary" @click.native="home" class="home">首页</el-button>
  </div>
</template>

<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { jsonPost, formPost } from '@/baseAPI'
export default {
  data() {
    return {
      recipeData: [],
      loading: false,
      patCardNo: ''
    }
  },
  created() {
    this.patCardNo = this.$route.query.patCardNo
    this.date = this.formatTime(-500)
    this.end = this.formatTime(0)
    this.getOrderList()
  },
  methods: {
    async getOrderList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const { data: res } = await jsonPost('/outpatient/getClinicPaidRecords', {
        beginDate: this.end,
        endDate: this.end,
        patCardType: '1',
        patCardNo: this.patCardNo,
        visitType: '1'
      })
      if (res.code === 0) {
        this.recipeData = res.data.item
        loading.close()
      } else {
        ;+loading.close()
        this.$message({
          message: res.msg,
          type: 'error'
        })
      }
    },
    home() {
      this.$router.push('/home')
    },
    on_select(row) {
      const params = {
        invoId: row.invoId,
        orderNum: row.orderNum,
        patCardNo: this.patCardNo,
        name: row.patName,
        date: this.end
      }
      this.$router.push({
        path: '/orderDetail',
        query: params
      })
    },
    formatTime(num) {
      var now = new Date()
      var nowTime = now.getTime()
      var oneDayTime = 24 * 60 * 60 * 1000
      var ShowTime = nowTime + num * oneDayTime
      var myDate = new Date(ShowTime)
      var y = myDate.getFullYear() //年
      var m = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 //月
      var d = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate() //日
      return [y, m, d].map(this.padStartConvert).join('-')
    },
    padStartConvert(n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    }
  }
}
</script>

<style lang="less">
.inpatient_container {
  width: 100%;
  height: 100%;
  //background-image: url('../../assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 30%;
}

.order_main {
  margin: 0 auto;
  width: 90%;
  height: 70%;
}

.order_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.order_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
  background-clip: padding-box !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
